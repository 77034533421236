import { jwtDecode } from 'jwt-decode';
import { useCallback } from 'react';
import { SERVICE } from 'src/configs/service';
import { useList } from 'src/hooks';
import { IUseListReturnValue } from 'src/hooks/dataProvider/type';
import { MetaQuery } from 'src/libs/dataProvider/types';

import { ILoyaltyPointsResponse, IPointsHistoryResponse, IRewardList, IUseLoyaltyDataProps } from '../types';

export const useLoyaltyData = ({
  meta,
  initialPage = 1,
  initialLimit = 50,
}: IUseLoyaltyDataProps) => {
  const leadId = extractLeadId(meta?.headers?.Authorization);

  const rewardsQuery = useRewardsQuery(meta, initialPage, initialLimit);
  const pointsQuery = usePointsQuery(meta, leadId);
  const pointsHistoryQuery = usePointsHistoryQuery(meta, initialLimit, leadId);

  const refetchAllData = useCallback(() => {
    rewardsQuery.refetch();
    pointsQuery.refetch();
    pointsHistoryQuery.refetch();
  }, [rewardsQuery, pointsQuery, pointsHistoryQuery]);

  return {
    leadId,
    ...processRewardsData(rewardsQuery),
    ...processPointsData(pointsQuery),
    ...processPointsHistoryData(pointsHistoryQuery),
    ...getOverallStatus(rewardsQuery, pointsQuery, pointsHistoryQuery),
    refetchAllData,
  };
};

const extractLeadId = (authToken?: string): string | undefined => {
  if (!authToken) return undefined;
  return (jwtDecode(authToken) as { lead_id?: string })?.lead_id;
};

const useRewardsQuery = (meta: any, initialPage: number, initialLimit: number) => 
  useList<IRewardList>({
    apiUrl: SERVICE.PROMO_V2,
    resource: 'v1/loyalties/rewards',
    meta: {
      ...meta,
      params: { page: initialPage, limit: initialLimit },
    },
  });

const usePointsQuery = (meta: any, leadId?: string) => 
  useList<ILoyaltyPointsResponse>({
    apiUrl: SERVICE.PROMO_V2,
    resource: 'v1/loyalties/points',
    meta: {
      ...meta,
      headers: meta.headers,
      params: { lead_id: leadId },
    },
  });

const usePointsHistoryQuery = (meta: MetaQuery, initialLimit: number, leadId?: string,) => 
  useList<IPointsHistoryResponse[]>({
    apiUrl: SERVICE.PROMO_V2,
    resource: 'v1/loyalties/points-histories',
    meta: {
      ...meta,
      params: {
        type: 'offset',
        query: JSON.stringify({
          limit: initialLimit,
          offset: 0,
          where: { and: { lead_id: { eq: leadId } } },
        }),
      },
    },
  });

const processRewardsData = (query: IUseListReturnValue<IRewardList>) => ({
  rewardList: Array.isArray(query.data?.data) ? query.data.data : [],
  isLoadingRewards: query.isLoading,
  isSuccessRewards: query.isSuccess,
  isErrorRewards: query.isError,
  refetchRewards: query.refetch,
});

const processPointsData = (query: IUseListReturnValue<ILoyaltyPointsResponse>) => ({
  loyaltyPoints: getLoyaltyPoints(query),
  isLoadingPoints: query.isLoading,
  isSuccessPoints: query.isSuccess,
  isErrorPoints: query.isError,
  refetchPoints: query.refetch,
});

const processPointsHistoryData = (query: IUseListReturnValue<IPointsHistoryResponse[]>) => ({
  pointsHistory: Array.isArray(query.data?.data) ? query.data.data : [],
  isLoadingPointsHistory: query.isLoading,
  isSuccessPointsHistory: query.isSuccess,
  isErrorPointsHistory: query.isError,
  refetchPointsHistory: query.refetch,
});

const getLoyaltyPoints = (query: IUseListReturnValue<ILoyaltyPointsResponse>): number => {
  if (query.data?.data) {
    const pointsData = query.data.data as unknown as ILoyaltyPointsResponse;
    return pointsData?.points || 0;
  }
  return 0;
};

const getOverallStatus = (...queries: IUseListReturnValue<any>[]) => ({
  isLoading: queries.some(q => q.isLoading),
  isSuccess: queries.every(q => q.isSuccess),
  isError: queries.some(q => q.isError),
});