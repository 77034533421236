import icloyalty1 from "src/assets/images/ic_loyalty1.webp";
import icloyalty2 from "src/assets/images/ic_loyalty2.webp";
import icloyalty3 from "src/assets/images/ic_loyalty3.webp";


export const contentsGuide = [
  {
    title: "Belanja di Toko Budidaya",
    image: icloyalty1,
  },
  {
    title: "Kumpulkan poin dari hasil belanja",
    image: icloyalty2,
  },
  {
    title: "Tukarkan poin dengan hadiah",
    image: icloyalty3,
  },
];

export const isLoyaltyActivated = "isLoyaltyActivated";
