import { Box, Button, Flex, Heading, Image, Text,VStack } from "@efishery/onefish";
import React from 'react';
import { PiXCircleFill } from "react-icons/pi";

interface RedeemResultProps {
  onClose: () => void;
  rewardImageUrl: string;
  isSuccess: boolean;
}

const Header: React.FC<{ isSuccess: boolean }> = ({ isSuccess }) => {
  const primaryColor = isSuccess ? "#4CC7A1" : "#D44140";
  const secondaryColor = isSuccess ? "#B7E9D9" : "#EEB3B3";


  return (
    <Box 
      position="relative"
      height="40vh"
      minH="300px"
      left="calc(50% + 16px)"
      transform="translateX(-50%)"
      marginLeft="-16px" 
      marginRight="-16px"
    >
      <svg width="100%" height="100%" viewBox="0 0 360 247" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice">
        <path d="M435 -8C435 25.4871 428.404 58.6462 415.589 89.5843C402.774 120.522 383.991 148.633 360.312 172.312C336.633 195.991 308.522 214.774 277.584 227.589C246.646 240.404 213.487 247 180 247C146.513 247 113.354 240.404 82.4157 227.589C51.4777 214.774 23.3667 195.991 -0.312241 172.312C-23.9912 148.633 -42.7743 120.522 -55.5893 89.5843C-68.4042 58.6462 -75 25.4871 -75 -8.00002L180 -8H435Z" fill={secondaryColor}/>
        <path d="M399 -8C399 20.7595 393.335 49.2374 382.33 75.8077C371.324 102.378 355.192 126.52 334.856 146.856C314.52 167.192 290.378 183.324 263.808 194.33C237.237 205.335 208.759 211 180 211C151.24 211 122.763 205.335 96.1923 194.33C69.622 183.324 45.4796 167.192 25.1436 146.856C4.80758 126.52 -11.3238 102.378 -22.3296 75.8076C-33.3354 49.2374 -39 20.7595 -39 -8.00002L180 -8H399Z" fill={primaryColor}/>
      </svg>
    </Box>
  );
};

const RewardImage: React.FC<{ imageUrl: string; isSuccess: boolean; }> = ({ imageUrl, isSuccess }) => (
  <Flex
    position="absolute"
    top="40vh"
    left="50%"
    transform="translate(-50%, -50%)"
    bg="white"
    borderRadius="full"
    p={4}
    justifyContent="center"
    alignItems="center"
    bgColor="#FFFFFF"
  >
    {isSuccess ? <Image src={imageUrl} boxSize="80px" objectFit="contain" /> : 
        <PiXCircleFill size="100px" color="#D44140"/>
    } 
  </Flex>
);

const Content: React.FC<{ isSuccess: boolean }> = ({ isSuccess }) => (
  <VStack spacing={4} px={4} pb={6}>
    <Heading size="md" textAlign="center" fontWeight="500">
      {isSuccess 
        ? "Selamat! Penukaran Poinmu Berhasil" 
        : "Penukaran Poinmu Belum Berhasil"}
    </Heading>
    <Text fontSize="sm" textAlign="center" color="gray.600">
      {isSuccess 
        ? "Silakan tunggu tim kami menghubungimu dalam waktu dekat untuk detail penyerahan hadiah."
        : "Tenang, poinmu belum dipotong, kok. Tunggu beberapa saat untuk coba lagi atau tukar dengan hadiah yang lain."}
    </Text>
  </VStack>
);

const CTAButton: React.FC<{ onClick: () => void; isSuccess: boolean }> = ({ onClick, isSuccess }) => (
  <Button
    width="100%"
    height="48px"
    bg="observatory.500"
    color="white"
    borderRadius="8px"
    fontWeight={500}
    fontSize="md"
    onClick={onClick}
  >
    {isSuccess ? "Kembali ke Beranda" : "Coba Lagi"}
  </Button>
);

export const RedeemResult: React.FC<RedeemResultProps> = ({ onClose, rewardImageUrl, isSuccess }) => {
  return (
    <>
      <Box 
        bg="white" 
        position="relative" 
        w="100%"
        h="60vh"
        data-testid={isSuccess ? "success-redeem-bottomsheet" : "failed-redeem-bottomsheet"}
      >
        <Header isSuccess={isSuccess} />
        <RewardImage imageUrl={rewardImageUrl} isSuccess={isSuccess} />
      </Box>
      <VStack 
        top={0}
        justifyContent="space-between" 
        h="calc(50vh - 40px)" 
        pt="40px"
        px={4}
      >
        <Content isSuccess={isSuccess} />
        <CTAButton onClick={onClose} isSuccess={isSuccess} />
      </VStack>
    </>
  );
};