import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { MobileLayout } from "src/components";

import { GuideLoyalty } from "../../components";
import { isLoyaltyActivated } from '../../constant';
import { ILoyaltyListPageProps } from "../../types";
import { LoyaltyContent } from "./LoyaltyContent";


const useLoyaltyState = () => {
  const [isActivated, setIsActivated] = useState(false);
  const [cookies, setCookie] = useCookies([isLoyaltyActivated]);

  const checkLoyaltyActivation = () => {
    if (cookies.isLoyaltyActivated) setIsActivated(true);
  };

  useEffect(() => {
    checkLoyaltyActivation();
  }, []);

  const handleStartLoyalty = () => {
    setIsActivated(true);
    setCookie(isLoyaltyActivated, true, {
      path: '/',
    });
  };

  return { isActivated, handleStartLoyalty };
};

const LoyaltyListPage: React.FC<ILoyaltyListPageProps> = ({
  chakra,
  footerProps,
  handleBack,
  useOuterContainer,
  resourceProps,
  goToBeliPakan,
}) => {
  const { isActivated, handleStartLoyalty } = useLoyaltyState();

  return (
    <MobileLayout
      chakra={chakra?.layout}
      footerProps={footerProps}
      useOuterContainer={useOuterContainer}
      data-testid="loyalty-home-page"
    >
      {!isActivated ? (
        <GuideLoyalty 
          onArrowBack={handleBack} 
          onStart={handleStartLoyalty}
          data-testid="guide-loyalty"
        />
      ) : (
        <LoyaltyContent 
          onArrowBack={handleBack} 
          meta={resourceProps?.meta}
          goToBeliPakan={goToBeliPakan}
        />
      )}
    </MobileLayout>
  );
};

export default LoyaltyListPage;