import { Box, Button, Flex, Image, Text } from "@efishery/onefish";
import mainKv from "src/assets/images/img_kv_loyalty.jpg";
import { MobileHeader } from "src/components/Header/mobile";
import { ENV } from "src/configs/env";
import { contentsGuide } from "src/features/Loyalty/constant";

interface GuideLoyaltyProps {
  onStart: () => void;
  onArrowBack: () => void;
  mainImage?: string;
}

export function GuideLoyalty({ onStart, onArrowBack, mainImage = mainKv }: GuideLoyaltyProps) {
  return (
    <Flex flexDirection="column" minHeight="100vh" bg="#FAFAFA">
      <MobileHeader
        transparent
        onPreviousClick={onArrowBack}
        chakra={{ colorScheme: "white" }}
      />

      <Box position="relative" width="100%" paddingTop="114%">
        <Image
          src={`${ENV.APP_HOST}${mainImage}`}
          alt="Main KV"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          objectFit="cover"
        />
      </Box>

      <Flex 
        flexDirection="column" 
        bg="black" 
        color="white" 
        padding="1rem"
        flex="1"
      >
        <Text
          fontWeight="700"
          fontSize="x-large"
          textAlign="center"
          mb="1.5rem"
        >
          Cara Dapatkan Poin
        </Text>

        <Flex justifyContent="space-between" mb="1rem">
          {contentsGuide.map((content, index) => (
            <Image 
              key={index}
              src={`${ENV.APP_HOST}${content.image}`} 
              alt={`Step ${index + 1}`} 
              borderRadius="8px"
              width="30%"
              height="auto"
              aspectRatio="1.18"
              objectFit="cover"
            />
          ))}
        </Flex>

        <Flex justifyContent="space-between" mb="2rem">
          {contentsGuide.map((content, index) => (
            <Text 
              key={index}
              width="30%" 
              textAlign="center" 
              fontSize="large"
              lineHeight="revert-layer"
              px="1"
            >
              {content.title}
            </Text>
          ))}
        </Flex>

        <Button
          mt="auto"
          width="100%"
          bg="#038767"
          color="white"
          borderRadius="8px"
          fontWeight="500"
          fontSize="16px"
          height="46px"
          onClick={onStart}
        >
          Buka Halaman Poin
        </Button>
      </Flex>
    </Flex>
  );
}