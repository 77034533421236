import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Divider, Flex, HStack, Image, Text, VStack } from "@efishery/onefish";
import React from 'react';
import icon1 from "src/assets/images/ic_loyalty_tnc1.webp"
import icon2 from "src/assets/images/ic_loyalty_tnc2.webp"
import icon3 from "src/assets/images/ic_loyalty_tnc3.webp"
import { ENV } from "src/configs/env";

const termsAndConditions = [
  "Setiap transaksi Rp1.000.000 di eFisheryKu akan mendapatkan 1 poin. Berlaku Kelipatan.",
  "Poin didapatkan ketika transaksi dilakukan tanpa menggunakan Promo.",
  "Poin didapatkan ketika transaksi telah memiliki invoice.",
  "Proses pengiriman hadiah maksimal 2 bulan dari redeem point dilakukan.",
  "Tidak diperbolehkan menukar hadiah yang sama lebih dari 1 kali.",
  "Syarat & Ketentuan dapat berubah sewaktu-waktu.",
];
const content = [
  {
    title: "Dapatkan 1 poin tiap belanja min. Rp 1 juta (berlaku kelipatan) di Toko Budidaya.",
    image: icon1,
  },
  {
    title: "Kumpulkan & tukarkan poinmu dengan hadiah yang tersedia.",
    image: icon2,
  },
  {
    title: "Cek aktivitas penukaran poinmu di menu 'Riwayat Poin'.",
    image: icon3,
  },
];

interface TitleSectionProps {
  onClose: () => void;
}

const TitleSection: React.FC<TitleSectionProps> = ({onClose}) => (
  <Flex
    justifyContent="space-between"
    alignItems="flex-start"
    p={2}
    width="100%"
    bg="white"
    data-testid="title-section"
  >
    <Text
      fontWeight={600}
      fontSize="xl"
      letterSpacing="0.5px"
    >
      Cara Penukaran Poin
    </Text>
    <CloseIcon color="#038767" onClick={onClose} data-testid="close-icon"/>
  </Flex>
);

const InstructionItem: React.FC<{ image: string; title: string; index: number }> = ({ image, title, index }) => (
  <HStack spacing={2} p={2} width="100%" height="88px" data-testid={`instruction-item-${index}`}>
    <Image src={`${ENV.APP_HOST}${image}`} width="100px" height="88px" borderRadius="8px" objectFit="cover" />
    <Text
      flex={1}
      fontWeight={400}
      fontSize="md"
      letterSpacing="0.5px"
      color="#1D1D1D"
    >
      {title}
    </Text>
  </HStack>
);

const TermsSection: React.FC = () => (
  <VStack align="flex-start" p={2} spacing={4} width="100%" data-testid="terms-section">
    <Text
      fontWeight={500}
      fontSize="xl"
      letterSpacing="0.5px"
      color="#1D1D1D"
    >
      Syarat & Ketentuan
    </Text>
    <VStack spacing={1} align="stretch" width="100%">
      {termsAndConditions.map((term, index) => (
        <Flex key={index} align="start" gap={2} data-testid={`term-item-${index}`}>
          <Text fontSize="xs" lineHeight="26px">●</Text>
          <Text
            fontSize="md"
            color="#1D1D1D"
          >
            {term}
          </Text>
        </Flex>
      ))}
    </VStack>
  </VStack>
);

interface CTAButtonProps {
  onClose: () => void;
}

const CTAButton: React.FC<CTAButtonProps> = ({onClose}) => (
  <Box
    width="100%"
    bg="white"
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.16)"
    data-testid="cta-button-container"
  >
    <Button
      width="100%"
      height="48px"
      bg="#038767"
      color="white"
      borderRadius="8px"
      fontWeight={500}
      fontSize="md"
      letterSpacing="0.5px"
      onClick={onClose}
      data-testid="cta-button"
    >
      Saya Mengerti
    </Button>
  </Box>
);

interface TermConditionProps {
  onClose: () => void;
}

export const TermCondition: React.FC<TermConditionProps> = ({onClose}) => (
  <Box
    bg="white"
    position="relative"
    data-testid="term-condition-container"
    maxHeight="85vh"
    overflow="auto"
  >
    <VStack spacing={0} align="stretch" pb={2}>
      <TitleSection onClose={onClose}/>
      <VStack spacing={3} py={4}>
        {content.map((item, index) => (
          <InstructionItem
            key={index}
            image={item.image}
            title={item.title}
            index={index}
          />
        ))}
      </VStack>
      <Divider />
      <TermsSection />
    </VStack>
    <CTAButton onClose={onClose}/>
  </Box>
);