import { useCallback, useState } from 'react';
import { SERVICE } from 'src/configs/service';
import { useOne } from 'src/hooks';
import { MetaQuery } from 'src/libs/dataProvider/types';

import { IRewardItem } from '../types';

export interface IUseLoyaltyDetailProps {
  meta: MetaQuery;
}

export const useLoyaltyDetail = ({ meta }: IUseLoyaltyDetailProps) => {
  const [rewardId, setRewardId] = useState<string | null>(null);

  const { data, isLoading, isError, refetch } = useOne<IRewardItem>({
    apiUrl: SERVICE.PROMO_V2,
    resource: 'v1/loyalties/rewards',
    id: rewardId || '',
    meta,
    enabled: !!rewardId, // Only enable when rewardId is available
  });

  const fetchLoyaltyDetail = useCallback( async(reward_id: string) => {
    await setRewardId(reward_id);
    refetch();
  }, [refetch]);

  return {
    data: data?.data || null,
    isLoading,
    isError,
    fetchLoyaltyDetail,
  };
};