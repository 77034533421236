import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading,Image,Spinner, Text,VStack } from "@chakra-ui/react";
import React from 'react';
import coin from "src/assets/icons/ic_loyalty.svg";
import coinGrey from "src/assets/icons/ic_loyalty_grey.svg";
import { ENV } from "src/configs/env";
import { formatDate } from "src/utils/formatDate";

import { IRewardItem,IRewardItemProps, IRewardList } from "../../types";

const RewardItem: React.FC<IRewardItemProps> = (({ 
  id, 
  image_url, 
  name, 
  quota_remaining, 
  points, end_date, 
  term_and_condition, 
  quota,
  loyaltyPoints, 
  handleCheckReward = () => null, 
  handleRedeemReward 
}) => (
    <Box
      width="100%"
      bg="white"
      borderWidth="1px"
      borderColor="#F7F7F7"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.16)"
      borderRadius="8px"
      overflow="hidden"
      data-testid={`reward-item-${id}`}
    >
      <Flex borderBottom="1px solid #F7F7F7">
        <Flex
          width="84px"
          justifyContent="center"
          alignItems="center"
          position="relative"
          overflow="hidden"
        >
          <Box
            width="110px"
            height="110px"
            bg="#FDE9D9"
            borderRadius="full"
            position="absolute"
            left="-50%"
          />
          <Image
            fallbackSrc={`${ENV.APP_HOST}${coinGrey}`}
            src={image_url}
            alt={`${name}-image`}
            width="80px"
            height="80px"
            objectFit="contain"
            zIndex={1}
            data-testid={`reward-image-${id}`}
          />
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          justifyContent="center"
          p="12px"
          gap="8px"
        >
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Text
                fontSize="xs"
                fontWeight="400"
                letterSpacing="0.5px"
                data-testid={`reward-remaining-${id}`}
              >
                {`Sisa ${quota_remaining} lagi!`}
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                letterSpacing="0.5px"
                data-testid={`reward-name-${id}`}
              >
                {name}
              </Text>
            </Box>
            <Button
              size="sm"
              bg="#F49342"
              color="white"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="0.5px"
              disabled={loyaltyPoints < points}
              data-testid={`redeem-button-${id}`}
              onClick={() => handleRedeemReward({ points, image_url, id, name})}
            >
              Tukar
            </Button>
          </Flex>
          <Flex alignItems="center" gap="8px">
            <Image
              src={`${ENV.APP_HOST}${coin}`}
              width="16px"
              data-testid="reward-coin-image"
            />
            <Text
              fontSize="xs"
              fontWeight="400"
              lineHeight="18px"
              letterSpacing="0.5px"
              data-testid={`reward-points-${id}`}
            >
              {`Butuh ${points} poin`}
            </Text>
          </Flex>
          <Text
            fontSize="xs"
            fontWeight="400"
            lineHeight="18px"
            letterSpacing="0.5px"
            data-testid={`reward-end-date-${id}`}
          >
            {`Tukar sebelum: ${formatDate(end_date)}`}
          </Text>
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p="4px 12px"
        height="32px"
        onClick={() => handleCheckReward({ id, image_url, name, points, end_date, term_and_condition, quota })}>
        <Text
          fontSize="xs"
          fontWeight="400"
          lineHeight="18px"
          letterSpacing="0.5px"
        >
          Cek S&K Hadiah
        </Text>
        <ChevronRightIcon fontSize={24} color="#1D1D1D" />
      </Flex>
    </Box>
));
  
const LoadingNoData: React.FC<{ isLoading: boolean }> = (({ isLoading }) => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="16px"
      width="100%"
      height="50vh"
      bg="#FFFFFF"
      borderRadius="8px"
      data-testid={isLoading ? "loading-data" : "no-data"}
    >
      <VStack spacing="16px" align="center">
        {isLoading ? (
          <Spinner color="white" size="sm" />
        ) : (
          <Image src={`${ENV.APP_HOST}${coinGrey}`} />
        )}
        <Text
          fontWeight="600"
          fontSize="xl"
          textAlign="center"
          color="#1D1D1D"
        >
          {isLoading ? "Memuat Data..." : "Hadiahnya Belum Tersedia"}
        </Text>
        <Text
          fontWeight="400"
          fontSize="sm"
          textAlign="center"
          color="#777777"
        >
          {isLoading
            ? "Mohon tunggu sebentar..."
            : "Silakan kembali lagi untuk cek & tukar poinmu dengan hadiah menarik."}
        </Text>
      </VStack>
    </Box>
));

interface IRewardListProps {
  rewardList: IRewardList[]; 
  isLoading: boolean, 
  handleCheckReward: any, 
  handleRedeemReward: any, 
  loyaltyPoints: number;
} 
  
export const RewardsList: React.FC<IRewardListProps> = (({ rewardList, isLoading, loyaltyPoints, handleCheckReward, handleRedeemReward }) => (
    <Box p={4} width="100%" data-testid="rewards-list">
      <Heading size="md" mb={4}>Daftar Hadiah</Heading>
      {rewardList.length === 0 || isLoading ? (
        <LoadingNoData isLoading={isLoading} />
      ) : (
        <VStack spacing={4} align="stretch">
          {rewardList.map((reward, index) => (
            <RewardItem 
              key={reward.id || index} 
              loyaltyPoints={loyaltyPoints} 
              {...reward as unknown as IRewardItem} 
              handleCheckReward={handleCheckReward} 
              handleRedeemReward={handleRedeemReward} />
          ))}
        </VStack>
      )}
    </Box>
));