import { ArrowRightIcon, CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, HStack, Image, Text, VStack } from "@efishery/onefish";
import React, { useEffect } from 'react';
import coin from "src/assets/icons/ic_loyalty.svg";
import { ENV } from "src/configs/env";

import { IRewardItem } from "../../types";

// Custom hook for managing redeem state
const useRedeemState = (initialRewardItem: IRewardItem) => {
  const [rewardItem, setRewardItem] = React.useState<IRewardItem>(initialRewardItem);
  const [isRedeeming, setIsRedeeming] = React.useState(false);

  const startRedeem = () => setIsRedeeming(true);
  const endRedeem = () => setIsRedeeming(false);

  return { rewardItem, isRedeeming, setRewardItem, startRedeem, endRedeem };
};

interface TitleSectionProps {
  onClose: () => void;
}

interface RedeemInfoProps {
  rewardItem: IRewardItem;
}

interface CTAButtonProps {
  onRedeem: () => void;
  isRedeeming: boolean;
}

interface RedemptionProps {
  onClose: () => void;
  onRedeem: () => void;
  rewardItem: IRewardItem;
}

const TitleSection: React.FC<TitleSectionProps> = ({ onClose }) => (
  <Flex
    justifyContent="space-between"
    alignItems="flex-start"
    p={2}
    width="100%"
    bg="white"
    data-testid="title-section"
  >
    <Heading as="h1" fontWeight={600} size="md" data-testid="redeem-title">
      Konfirmasi Penukaran Poin
    </Heading>
    <CloseIcon
      color="#038767"
      onClick={onClose}
      data-testid="close-icon"
      cursor="pointer"
      aria-label="Close"
    />
  </Flex>
);

const RedeemInfo: React.FC<RedeemInfoProps> = ({ rewardItem }) => (
  <Flex
    flexDirection="row"
    justifyContent="center"
    alignItems="center"
    gap={6}
    pb={4}
    height="100px"
    data-testid="redeem-info-section"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100px"
      height="100px"
      background="#F7F7F7"
      borderRadius="8px"
    >
        <HStack spacing="8px" alignItems="center">
            <Image
                src={`${ENV.APP_HOST}${coin}`}
                data-testid="loyalty-coin-image"
                boxSize="32px"
                alt="Loyalty Coin"
            />
            <Text
                fontWeight={500}
                fontSize="xl"
                data-testid="points-required-value"
            >
                {rewardItem.points}
            </Text>
        </HStack>
    </Flex>

    <ArrowRightIcon boxSize="20px" />

    <Flex
      justifyContent="center"
      alignItems="center"
      width="100px"
      height="100px"
      background="#F7F7F7"
      borderRadius="8px"
    >
      <Image
        src={rewardItem.image_url}
        alt={rewardItem.name}
        width="80px"
        height="80px"
        objectFit="contain"
        data-testid="reward-image"
      />
    </Flex>
  </Flex>
);

const CTAButton: React.FC<CTAButtonProps> = ({ onRedeem, isRedeeming }) => (
  <Box
    width="100%"
    bg="white"
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.16)"
    data-testid="cta-button-container"
  >
    <Button
      width="100%"
      height="48px"
      onClick={onRedeem}
      isLoading={isRedeeming}
      loadingText="Menukar..."
      data-testid="redeem-button"
    >
      Tukarkan Poin Saya
    </Button>
  </Box>
);

export const Redemption: React.FC<RedemptionProps> = ({ onClose, onRedeem, rewardItem: initialRewardItem }) => {
  const { rewardItem, isRedeeming, setRewardItem, startRedeem, endRedeem } = useRedeemState(initialRewardItem);


  useEffect(() => {
    setRewardItem(initialRewardItem)
  }, [initialRewardItem])

  const handleRedeem = async () => {
    startRedeem();
    try {
        await onRedeem()
    }  finally {
      endRedeem();
    }
  };

  if (!rewardItem) return null;

  return (
    <Flex data-testid="redeem-bottom-sheet">
      <VStack align="stretch" spacing={4}>
        <TitleSection onClose={onClose} />
        <RedeemInfo rewardItem={rewardItem} />
        <VStack align="center" spacing={2} px={4}>
          <Text fontWeight={500} fontSize="16px" lineHeight="24px" textAlign="center">
            Lanjut tukar {rewardItem.points} poin dengan {rewardItem.name}?
          </Text>
          <Text fontSize="14px" lineHeight="22px" textAlign="center" color="#4A4A4A">
            Total poinmu akan langsung dipotong sesuai jumlah yang dibutuhkan. Poin akan dikembalikan jika terjadi kegagalan dalam proses penukaran.
          </Text>
        </VStack>
        <CTAButton onRedeem={handleRedeem} isRedeeming={isRedeeming} />
      </VStack>
    </Flex>
  );
};