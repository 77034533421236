import 'dayjs/locale/id';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (dateString: string): string => {
    return dayjs(dateString).tz('Asia/Jakarta').locale('id').format('DD MMM YYYY');
};
