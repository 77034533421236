import { ChevronRightIcon } from '@chakra-ui/icons';
import { AspectRatio, Box, Button, Divider, Flex, Image, Text, VStack } from "@efishery/onefish";
import React, { useState } from 'react';
import coin from "src/assets/icons/ic_loyalty.svg";
import imgShape from "src/assets/images/img_shape.svg";
import imgWave from "src/assets/images/img_wave.svg";
import { BottomSheet, MobileHeader } from 'src/components';
import { ENV } from 'src/configs/env';
import { SERVICE } from 'src/configs/service';
import { useCreate } from 'src/hooks';
import useSimpleDisclosure from 'src/hooks/useSimpleDisclosure'

import { RedeemResult } from '../../components/RedeemResult';
import { useLoyaltyData } from '../../hooks/useLoyaltyData'
import { IPointsHistoryItem, IRewardItem} from '../../types';
import { DetailReward } from './DetailReward';
import { Redemption } from './Redemption';
import { RewardsList } from './RewardList';
import { RiwayatLoyalty } from './RiwayatLoyalty';
import { TermCondition } from './TermCondition';
  
const HeaderSection: React.FC<{
    totalPoints: number;
    expiryDate: string;
    onArrowBack: () => void;
  }> = ({ totalPoints, expiryDate, onArrowBack }) => (
    <>
      <MobileHeader
        onPreviousClick={onArrowBack}
        chakra={{
          container: { bg: "#038767" },
          colorScheme: 'white'
        }}
        data-testid="mobile-header"
      />
      <VStack position="relative" w="full">
        <AspectRatio ratio={7 / 2} width="full">
          <Image
            src={`${ENV.APP_HOST}${imgWave}`}
            backgroundColor="#038767"
            width="full"
            objectFit="cover"
            data-testid="loyalty-header-image"
          />
        </AspectRatio>
        <Flex
          position="absolute"
          flexDirection="column"
          justifyContent="center"
          gap={1}
          alignItems="center"
          width="full"
        >
          <Text
            fontSize="sm"
            color="white"
            fontWeight="medium"
            data-testid="total-points-label"
          >
            Total Poin
          </Text>
          <Flex justifyContent="center" gap="4" alignItems="center">
            <Image
              src={`${ENV.APP_HOST}${coin}`}
              sizes="xl"
              data-testid="loyalty-coin-image"
            />
            <Text
              fontWeight="500"
              fontSize="8xl"
              color="white"
              data-testid="total-points-value"
            >
              {totalPoints}
            </Text>
          </Flex>
          <Text
            fontWeight="700"
            fontSize="xs"
            color="white"
            data-testid="expiry-date"
          >
            Berlaku hingga {expiryDate}
          </Text>
        </Flex>
      </VStack>
    </>
);
  
const InfoItem: React.FC<{
    title: string;
    badge?: number;
    onClick: () => void;
  }> = (({ title, badge, onClick }) => (
    <Flex
      width="100%"
      height="48px"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      onClick={onClick}
      cursor="pointer"
      backgroundColor="#FFFFFF"
      data-testid={`info-item-${title.toLowerCase().replace(/\s+/g, '-')}`}
    >
      <Flex alignItems="center">
        <Text
          fontWeight={500}
          fontSize="16px"
          lineHeight="24px"
          letterSpacing="0.5px"
          color="#1D1D1D"
        >
          {title}
        </Text>
        {badge && (
          <Flex
            ml={2}
            width="20px"
            height="18px"
            bg="#D44140"
            borderRadius="40px"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontWeight={600} fontSize="12px" color="white">
              {badge}
            </Text>
          </Flex>
        )}
      </Flex>
      <ChevronRightIcon />
    </Flex>
));

const PointsPromotionBox: React.FC<{goToBeliPakan: () => void;}> = (({goToBeliPakan}) => (
  <VStack
    position="relative"
    bg="#DBF4EC"
    borderRadius="8px"
    m={4}
    overflow="hidden"
    height="100px"
    data-testid="points-promotion-box"
  >
    <AspectRatio ratio={5/2} width="full">
      <Image src={`${ENV.APP_HOST}${imgShape}`} />
    </AspectRatio>
    <Flex
      position="absolute"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="full"
      p={4}
    >
      <Text
        textAlign="center"
        fontWeight={500}
        fontSize="sm"
        pb={2}
      >
        Mau tambah lagi poinmu?
      </Text>
      <Button
        bg="#038767"
        width="full"
        fontWeight={500}
        fontSize="md"
        data-testid="buy-feed-button"
        onClick={() => goToBeliPakan()}
      >
        Belanja di Toko Budidaya
      </Button>
    </Flex>
  </VStack>
));

const BOTTOM_SHEET_TYPES = {
  TERM_CONDITION: 'TermCondition',
  RIWAYAT_LOYALTY: 'RiwayatLoyalty',
  REDEEM_RESULT: 'RedeemResult',
};

interface ILoyaltyContentProps {
  onArrowBack: () => void;
  meta: any;
  goToBeliPakan: () => void;
}
  
export const LoyaltyContent: React.FC<ILoyaltyContentProps> = (({ onArrowBack, meta, goToBeliPakan }) => {
    const { isOpen: isTermConditionOpen, onOpen: onTermConditionOpen, onClose: onTermConditionClose } = useSimpleDisclosure();
    const { isOpen: isRiwayatLoyaltyOpen, onOpen: onRiwayatLoyaltyOpen, onClose: onRiwayatLoyaltyClose } = useSimpleDisclosure();
    const { isOpen: isDetailRewardOpen, onOpen: onDetailRewardOpen, onClose: onDetailRewardClose } = useSimpleDisclosure();
    const { isOpen: isRedemptionOpen, onOpen: onRedemptionOpen, onClose: onRedemptionClose } = useSimpleDisclosure();
    const { isOpen: isRedeemResultOpen, onOpen: onRedeemResultOpen, onClose: onRedeemResultClose } = useSimpleDisclosure();
    const { rewardList, loyaltyPoints, pointsHistory, isLoading, leadId, refetchAllData } = useLoyaltyData({ meta });
    const [selectedReward, setSelectedReward] = useState<IRewardItem | null>(null);
    const [isRedeemSuccess, setIsRedeemSuccess] = useState<boolean>(false)
    const { mutate } = useCreate({ apiUrl: SERVICE.PROMO_V2 });

    const handleClick = (item: string) => {
      switch (item) {
        case BOTTOM_SHEET_TYPES.TERM_CONDITION:
          onTermConditionOpen();
          break;
        case BOTTOM_SHEET_TYPES.RIWAYAT_LOYALTY:
          onRiwayatLoyaltyOpen();
          break;
        case BOTTOM_SHEET_TYPES.REDEEM_RESULT:
          onRedeemResultOpen();
          break;
        default:
          onArrowBack();
          break;
      }
    };

  const handleCheckReward = (reward: IRewardItem) => {
    setSelectedReward(reward);
    // closing when accessed from riwayat, prevent double click
    onRiwayatLoyaltyClose() 
    onDetailRewardOpen();
  };

  const handleRedeemReward = (reward: IRewardItem) => {
    setSelectedReward(reward);
    onRedemptionOpen();
  };

  const closeAllBottomSheet = () => {
    onTermConditionClose()
    onRiwayatLoyaltyClose()
    onDetailRewardClose()
    onRedemptionClose()
    onRedeemResultClose()
  }

  const onRedeem = () => {
    if (selectedReward) {
      mutate({
        resource: 'v1/loyalties/rewards/redemption',
        meta,
        variables: {
          id: selectedReward.id,
          lead_id: leadId,
        },
        onSuccess: () => {
          setIsRedeemSuccess(true)
          onRedeemResultOpen()
          refetchAllData()
        },
        onError: () => {
          setIsRedeemSuccess(false)
          onRedeemResultOpen()
        },
      });
    }
  }
  
    return (
      <>
        <VStack
          width="full"
          boxShadow="sm"
          minHeight="100vh"
          spacing="0"
          position="relative"
          alignItems="stretch"
          bg="#FFFFFF"
          data-testid="loyalty-content"
        >
          <HeaderSection totalPoints={loyaltyPoints} onArrowBack={onArrowBack} expiryDate="15 Des 2024" />
          <VStack
            width="full"
            padding="16px 16px 0px"
            gap="10px"
            alignItems="flex-start"
          >
            <Box
              width="full"
              border="2px solid #E2E8F0"
              borderRadius="8px"
              overflow="hidden"
            >
              <InfoItem
                title="Cara Penukaran Poin"
                onClick={() => handleClick(BOTTOM_SHEET_TYPES.TERM_CONDITION)}
                data-testid="info-item-cara-penukaran"
              />
              <Divider />
              <InfoItem
                title="Riwayat Poin"
                onClick={() => handleClick(BOTTOM_SHEET_TYPES.RIWAYAT_LOYALTY)}
                data-testid="info-item-riwayat-poin"
              />
            </Box>
          </VStack>
          <RewardsList loyaltyPoints={loyaltyPoints} rewardList={rewardList} isLoading={isLoading} handleCheckReward={handleCheckReward} handleRedeemReward={handleRedeemReward}/>
          <PointsPromotionBox goToBeliPakan={goToBeliPakan}/>
        </VStack>
        <BottomSheet
          isOpen={isTermConditionOpen}
          setIsOpen={onTermConditionClose}
          data-testid="bottom-sheet-term-condition"
        >
          <TermCondition onClose={onTermConditionClose} />
        </BottomSheet> 
        <BottomSheet
          isOpen={isRiwayatLoyaltyOpen}
          setIsOpen={onRiwayatLoyaltyClose}
          data-testid="bottom-sheet-riwayat-loyalty"
        >
          <RiwayatLoyalty pointsHistory={pointsHistory as unknown as IPointsHistoryItem[]} onClose={onRiwayatLoyaltyClose} handleCheckReward={handleCheckReward as any}/>
        </BottomSheet>
        {selectedReward && <BottomSheet
          isOpen={isDetailRewardOpen}
          setIsOpen={onDetailRewardClose}
          data-testid="bottom-sheet-detail-reward"
        >
          <DetailReward rewardItem={selectedReward} meta={meta} onClose={onDetailRewardClose} />
        </BottomSheet>}
        {selectedReward && <BottomSheet
          isOpen={isRedemptionOpen}
          setIsOpen={onRedemptionClose}
          data-testid="bottom-sheet-redemption"
        >
          <Redemption rewardItem={selectedReward} onClose={onRedemptionClose} onRedeem={onRedeem}/>
        </BottomSheet>}
        {selectedReward && <BottomSheet
          isOpen={isRedeemResultOpen}
          setIsOpen={closeAllBottomSheet}
          data-testid="bottom-sheet-redemption-status"
        >
          <RedeemResult isSuccess={isRedeemSuccess} onClose={closeAllBottomSheet} rewardImageUrl={selectedReward.image_url} />
        </BottomSheet>}
      </>
    );
  });