import { CloseIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, Heading, HStack, Image, Text, VStack } from "@efishery/onefish";
import React, { useEffect, useState } from 'react';
import coin from "src/assets/icons/ic_loyalty.svg";
import { ENV } from "src/configs/env";
import { MetaQuery } from "src/libs/dataProvider/types";

import { useLoyaltyDetail } from '../../hooks/useLoyaltyDetail';
import { IRewardDetailResponse, IRewardItem } from "../../types";

interface TitleSectionProps {
  onClose: () => void;
}

const TitleSection: React.FC<TitleSectionProps> = ({ onClose }) => (
  <Flex
    justifyContent="space-between"
    alignItems="flex-start"
    p={2}
    width="100%"
    bg="white"
    data-testid="title-section"
  >
    <Heading as="h1" fontWeight={600} size="md" data-testid="detail-reward-title">
      Detail Hadiah
    </Heading>
    <CloseIcon color="#038767" onClick={onClose} data-testid="close-icon" cursor="pointer" />
  </Flex>
);

interface CTAButtonProps {
  onClose: () => void;
}

const CTAButton: React.FC<CTAButtonProps> = ({ onClose }) => (
  <Box
    width="100%"
    bg="white"
    boxShadow="0px 2px 8px rgba(0, 0, 0, 0.16)"
    data-testid="cta-button-container"
  >
    <Button
      width="100%"
      height="48px"
      bg="#038767"
      color="white"
      borderRadius="8px"
      fontWeight={500}
      fontSize="md"
      letterSpacing="0.5px"
      onClick={onClose}
      data-testid="cta-button"
    >
      Tutup
    </Button>
  </Box>
);

interface RewardInfoProps {
  rewardItem: IRewardItem;
}

const RewardInfo: React.FC<RewardInfoProps> = ({ rewardItem }) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    gap="20px"
    pb={4}
    borderBottom="1px solid #D2D2D2"
    data-testid="reward-info-section"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100px"
      height="100px"
      background="#F7F7F7"
      borderRadius="20px"
    >
      <Image
        src={rewardItem.image_url}
        alt={rewardItem.name}
        width="80px"
        height="80px"
        objectFit="contain"
        data-testid="reward-image"
      />
    </Flex>

    <VStack
      alignItems="flex-start"
      width="3/4"
      padding={2}
    >
      <Heading fontWeight={600} size="md" as="h2" data-testid="reward-name">
        {rewardItem.name}
      </Heading>

      <VStack alignItems="flex-start">
        <HStack spacing="8px">
          <Image
            src={`${ENV.APP_HOST}${coin}`}
            data-testid="loyalty-coin-image"
            boxSize="6"
          />
          <Text
            fontWeight={400}
            fontSize="sm"
            color="#A5A5A5"
            data-testid="points-required-label"
          >
            Poin yang Dibutuhkan
          </Text>
        </HStack>

        <Text fontSize="sm" ml="8" data-testid="points-required-value">
          {rewardItem.points} poin
        </Text>
      </VStack>
    </VStack>
  </Flex>
);

interface TermsAndConditionsProps {
  content: string;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ content }) => (
  <VStack width="full" alignItems="flex-start" p={2}>
    <Heading size="md" data-testid="terms-conditions-title">
      Syarat dan Ketentuan
    </Heading>
    <Box
      dangerouslySetInnerHTML={{ __html: content }}
      fontSize="sm"
      width="full"
      data-testid="terms-conditions-content"
      p={2}
      minHeight="30vh"
    />
  </VStack>
);

interface DetailRewardProps {
  onClose: () => void;
  rewardItem: IRewardItem;
  meta: MetaQuery;
}

export const DetailReward: React.FC<DetailRewardProps> = ({ onClose, meta, rewardItem: initialRewardItem }) => {
  const { data, isLoading, isError, fetchLoyaltyDetail } = useLoyaltyDetail({ meta });
  const [loyaltyDetailData, setLoyaltyDetailData] = useState(initialRewardItem)

  useEffect(() => {
    if (!initialRewardItem.image_url) {
      fetchLoyaltyDetail(initialRewardItem.reward_id as unknown as string);
    } else {
      setLoyaltyDetailData(initialRewardItem)
    }
  }, [initialRewardItem]);

  useEffect(() => {
    if ((data as IRewardDetailResponse)?.success) {
      setLoyaltyDetailData((data as IRewardDetailResponse)?.data)
    }
  }, [data])

  if (isLoading) return <div>Loading...</div>;
  if (isError && !initialRewardItem?.image_url) return <VStack><TitleSection onClose={onClose} /><div>Error loading reward details {initialRewardItem.name}</div><CTAButton onClose={onClose} /></VStack>;

  return (
    <Box
      bg="white"
      position="relative"
      data-testid="bottom-sheet-detail-reward"
    >
      <VStack align="stretch" spacing={4}>
        <TitleSection onClose={onClose} />
        <RewardInfo rewardItem={loyaltyDetailData} />
        <TermsAndConditions content={loyaltyDetailData.term_and_condition} />
      </VStack>
      <CTAButton onClose={onClose} />
    </Box>
  );
};